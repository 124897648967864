<template>
  <v-popover offset="5" :placement="popoverPlacement" :open="popoverOpen" :disabled="sidebarOpened" popoverClass="dashboard_popover notice" popoverWrapperClass="dashboard_popover_wrapper" popoverInnerClass="dashboard_popover_content" boundariesElement=".popover_trigger">
    <div class="popover_trigger">
      <CButton class="m-0" color="primary" @click="openSidebarRight('add_product_auto_moments', { product_tag:  productTag, show_instructions: true }); sidebarOpened = true">
        <i class="fas fa-plus mr-1"/>
        <span>{{$t('communications.Add_auto_moments')}}</span>
      </CButton>
    </div>

    <template slot="popover">
      <CCard class="mb-0">
        <CCardBody>
          <CRow>         
            <CCol cols="12" lg="12" class="pt-0 pb-0">
              <span>{{$t('communications.Tap_to_add_' + productTag + '_moments')}}</span>
            </CCol>            
          </CRow>       
        </CCardBody>
      </CCard>
    </template>
  </v-popover>
</template>

<script>

export default {
  name: 'NewMomentPopover',
  props: ['placement', 'open', 'productTag'],
  watch: {
    $props: {
      handler() {
        if(this.placement) this.popoverPlacement = this.placement;
        if(this.open) this.popoverOpen = this.open;
      },
      deep: true,
      immediate: true
    }
  },
  data() {
    return {
      popoverPlacement: 'left',
      popoverOpen: true,
      sidebarOpened: false
    }
  }
}
</script>